@import '../../../colors.scss';

.orders-history-wrap {
  margin-top: 30px;
  .ant-table-row {
    cursor: pointer;
  }
  .ant-table-cell {
    i {
      font-size: 18px;
      display: block;
      // margin-top: -8px;
      &.red {
        color: $red;
        margin-top: -8px;
      } 
      &.blue {
        margin-top: -8px;
        color: $blue;
      } 
    }
    .card {
      width: 35px;
    }
  }
  .circle {
    display: flex;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    background-color: $offGreen;
    color: $green;
    margin: 0 auto;
    i {
      line-height: 0;
    }
    &.danger {
      background-color: $offRed;
      color: $red;
    }
  }
}