@import '../../../colors.scss';

.order-detail-wrap {
  .print-wrap {
    position: relative;
    .print-btn {
      position: absolute;
      right: 80px;
      top: -75px;
      background-color: $blue;
      border-color: $blue;
      &.userPrint {
        right: 40px;
        .print-icon {
          font-size: 16px;
        }
      }
      .print-icon {
        display: block;
        // margin-top: -5px;
        font-size: 12px;
      }
    }
  }
  .box {
    border: 1px solid $borderGray;
    padding: 12px 15px;
    border-radius: 10px;
  }
  .image-promo {
    width: 100%;
    margin-bottom: -10px;
    margin-top: 5px;
  }
  .horizontal {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cat-wrapper {
    margin-bottom: 15px;
    .cat-main {
      margin-bottom: -15px;
      display: block;
      font-size: 10px;
      font-weight: 700;
      color: $darkGray;
    }
  }
  .price {
    font-weight: 800;
    color: teal;
  }
  .label {
    font-weight: 500;
  }
  .comments {
    font-weight: 500;
    margin-top: 15px;
  }
  .cashback {
    margin-top: 15px;
  }
  .product-wrap {
    margin-bottom: 15px;
    .product {
      margin-bottom: 15px;
      .product-header {
        display: flex;
        align-items: center;
        .quantity-wrap {
          width: 27px;
          height: 27px;
          display: flex;
          margin-right: 10px;
          align-items: center;
          justify-content: center;
          background-color: $borderGray;
          border-radius: 5px;
          // font-family: BwModelicaS001;
          font-weight: 500;
        }
        .product-name {
          flex: 1;
        }
      }
      .product-extras {
        margin-top: 15px;
        .extra {
          margin-top: 15px;
        }
      }
    }
  }
  .totals {
    margin-top: 20px;
    .box {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .label {
      color: $gray;
    }
    .subtotal, .iva {
      .price {
        color: $gray;
      }
    }
    .delivery {
      .price {
        color: $yellow;
      }
    }
    .total {
      .label, .price {
        color: $black;
        font-weight: 700;
      }
    }
  }
  .method-wrap {
    padding: 24px 24px 0 24px;
    border-top: 1px solid $borderGray;
    margin: 24px -24px 0 -24px;
    .label {
      color: $gray;
    }
    .method {
      color: $black;
      font-weight: 700;
    }
  }
}

.loader-detail-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}