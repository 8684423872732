@import '../../../colors.scss';

.settlement-detail-wrap {
  .link {
    color: $teal;
    font-weight: 800;
    font-family: BwModelicaS001;
    font-size: 20px;
  }
  .main-titles {
    display: flex;
    align-items: flex-end;
  }
  .number-orders {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .number-data {
      text-align: center;
      margin-left: 40px;
    }
    .action {
      display: block;
      margin-left: 20px;
      padding: 9px 15px;
      border-radius: 5px;
      font-weight: 700;
      &.purple {
        background-color: $offPurple;
        color: $purple;
      }
      &.blue {
        background-color: $offBlue;
        color: $blue;
      }
      &.green {
        background-color: $offGreen;
        color: $green;
      }
    }
  }
  .summary {
    display: flex;
    margin-bottom: 35px;
    justify-content: space-between;
    .summary-item {
      // margin-right: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .label-summary {
        margin-right: 5px;
        color: $darkGray;
        font-size: 16px;
      }
      .value-summary {
        font-size: 20px;
        // font-family: BwModelicaS001;
        font-weight: 800;
      }
    }
  }
  .orders-wrap-settlement {
    margin-top: 40px;
  }
}