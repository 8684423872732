@import "../../../colors.scss";

.order-monitor {
  border: 1px solid $borderGray;
  border-radius: 20px;
  position: relative;
  .header-order {
    border-bottom: 1px solid $borderGray;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: BwModelicaS001;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    .id {
      display: inline-block;
      font-size: 10px;
      margin-right: 5px;
    }
  }
  .red {
    color: $red;
  }
  .tn-wrap {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 10px;
    border-radius: 100%;
    background-color: $offBlue;
    color: $blue;
    border: 1px solid $blue;
    position: absolute;
    top: -10px;
    right: -10px;
    line-height: 0;
  }
  .detail-order {
    // height: 200px;
    padding: 15px 15px 3px 15px;
    position: relative;
    .status {
      width: 40px;
      height: 40px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 16px;
      &.offRed {
        background-color: $offRed;
      }
      &.offYellow {
        background-color: $offYellow;
      }
      &.offTeal {
        background-color: $offTeal;
        font-size: 20px;
      }
    }
    .payToPartner {
      position: absolute;
      top: 65px;
      right: 15px;
    }
    .payment svg {
      width: 20px;
      height: 20px;
    }
    .cashPayment svg {
      color: $teal;
    }
    .cardPayment svg {
      color: $yellow;
    }
    .payToPartner {
      font-weight: bold;
    }
    .progress {
      .ant-steps-item {
        &:first-child {
          margin-top: -20px;
        }
        .ant-steps-item-container {
          .logo {
            width: 40px;
            height: 40px;
            background-size: cover;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $black;
            font-size: 16px;
            border: 1px solid $offGray;
            &.no-driver {
              background-color: $offYellow;
            }
            &.address {
              background-color: $offGray;
            }
          }
          .ant-steps-item-content {
            margin-top: 20px;
            .gray {
              color: $gray;
            }
            .ant-steps-item-title {
              margin-top: -6px;
              font-weight: 700;
            }
            .ant-steps-item-description {
              margin-top: -6px;
              color: $black;
              font-weight: 500;
              font-size: 14px;
            }
            .ant-steps-item-title,
            .ant-steps-item-description {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              display: inline-block;
              width: 100%;
            }
          }
          .ant-steps-item-tail {
            top: 20px !important;
            left: 20px !important;
            padding: 45px 0 6px !important;
            &:after {
              background-color: $borderGray;
            }
          }
        }
      }
    }
  }
  .action-wrap {
    padding: 10px 15px 15px 15px;
    button {
      border-radius: 10px;
      background-color: $offGray;
      color: $black;
      border-color: $offGray;
      border: none;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 15px;
      font-weight: 500;
      line-height: 0;
      i {
        font-size: 18px;
      }
      .app {
        color: $blue;
      }
      .call {
        color: $red;
      }
    }
  }
}
