@import '../../../colors.scss';

.partners-list-take-order {
  border: 1px solid $borderGray;
  padding: 20px;
  border-radius: 20px;
  margin-top: 30px;
  .loader-wrap {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .partner {
    cursor: pointer;
    .partner-cover {
      height: 150px;
      background-position: center center;
      background-size: cover;
      border-radius: 10px;
      position: relative;
      display: flex;
      .partner-logo {
        width: 60px;
        height: 60px;
        background-position: center center;
        background-size: cover;
        border-radius: 100%;
        border: 2px solid $white;
        position: absolute;
        bottom: -30px;
        right: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      }
      .filter {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-weight: 700;
        font-size: 18px;
        background-color: rgba($black, 0.5);
        border-radius: 10px;
      }
    }
    .partner-info {
      margin-top: 5px;
      .partner-name {
        font-family: BwModelicaS001;
        font-weight: 800;
        font-size: 16px;
      }
      .partner-details {
        display: flex;
        align-items: center;
        font-weight: 500;
        i {
          line-height: 5px;
          display: inline-block;
        }
        span {
          display: inline-block;
          margin-right: 15px;
          margin-left: 5px;
        }
        .star {
          color: $yellow;
        }
        .clock {
          color: $teal;
        }
      }
    }
  }
}