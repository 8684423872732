@import '../../../colors.scss';

.address-ui {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $borderGray;
  cursor: pointer;
  svg {
    color: $black;
    margin-bottom: 15px;
  }
  .active {
    color: $teal;
  }
  .main-info {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .info-address {
    margin-left: 15px;
    flex: 1;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    margin-right: -15px;
    padding-right: 15px;
    .texts {
      flex: 1;
      span {
        display: block;
      }
      .name {
        font-weight: 700;
      }
      .address-preview {
        font-size: 13px;
      }
    }
  }
  .actions {
    display: flex;
    margin-left: 10px;
    height: 100%;
    button {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
      font-size: 12px;
    }
    .delete {
      margin-left: 10px;
      background-color: $offRed;
      border-color: $offRed;
      color: $red;
    }
    .edit {
      background-color: $offBlue;
      border-color: $offBlue;
      color: $blue;
    }
  }
}
