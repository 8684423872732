@import '../../../colors.scss';

.feedSortUI {
  .partners-wrap {
    .check-btn {
      border-color: $teal;
      background-color: $teal;
      float: right;
    }
  
    .table-data {
      margin-top: 40px;
    }
  
    .ant-table-row {
      cursor: pointer;
    }
  
  }
  
  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }
  
  .row-dragging td {
    padding: 16px;
  }
  
  .row-dragging .drag-visible {
    visibility: visible;
  }
  
  .new {
    float: right;
  }
  
  .action {
    display: block;
    margin-left: 20px;
    padding: 9px 15px;
    border-radius: 5px;
    font-weight: 700;
    &.purple {
      background-color: $offPurple;
      color: $purple;
    }
  }
}