@import '../../../colors.scss';

.login-wrap {
  height: 100vh;
  .background {
    height: 100vh;
    background-image: url(../../../assets/backgrounds/login.svg);
    background-size: cover;
    background-position: center center;
  }
  .login-form {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    img {
      position: absolute;
      top: 40px;
      left: 40px;
      width: 80px;
    }
    .ant-input-password-icon {
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: -5px;
    }
    .title {
      font-family: BwModelicaS001;
      font-size: 22px;
      font-weight: 800;
      display: block;
      margin-bottom: 40px;
    }
    .label {
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 1px;
    }
    .input {
      background-color: $borderGray;
      border-radius: 12px;
      height: 50px;
      border: none;
      padding-left: 20px;
      padding-right: 20px;
      input {
        background-color: $borderGray;
      }
    }
    .radios {
      display: flex;
      align-items: center;
      .radio-wrap {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 40px;
        .radio-ext {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          border: 1px solid $green;
          border-radius: 100%;
          margin-right: 10px;
          .inner-radio {
            background-color: $green;
            width: 16px;
            height: 16px;
            border-radius: 100%;
          }
        }
      }
    }
    .submit {
      background-color: $teal;
      border: none;
      height: 50px;
      border-radius: 12px;
      color: $white;
      margin-top: 20px;
      font-weight: 700;
    }
  }
}

@media (max-width: 576px) {
  .login-wrap {
    .login-form {
      padding-left: 20px;
      padding-right: 20px;
      img {
        left: 20px;
      }
    }
  }
}