@import '../../../colors.scss';

.cash-summary {
  overflow-y: scroll;
  .header {
    display: flex;
    align-items: center;
    justify-content:space-between;
    .title, .clean {
      font-weight: 800;
      font-size: 12px;
    }
    .clean {
      color: $red;
      background-color: transparent;
    }
  }
  .details {
    margin-top: 15px;
    .header-details {
      background-color: #F9F9F9;
      border: 1px solid #F9F9F9;
      padding: 10px 15px;
      font-weight: 700;
      display: flex;
      border-radius: 3px 3px 0 0;
      .denomination {
        flex: 1;
      }
      .amount, .total {
        width: 80px;
      }
      .amount {
        display: flex;
        text-align: center;
        margin: 0 10px;
        padding-left: 10px;
      }
      .total {
        text-align: right;
      }
    }
    .body-details {
      padding: 15px;
      border-style: solid;
      border-color: #E8E7E7;
      border-width: 0 1px 1px 1px;
      border-radius: 0 0 3px 3px;
      .detail {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .label-detail {
        flex: 1;
      }
      .input-cash, .amount {
        width: 80px;
      }
      .input-cash {
        text-align: center;
        margin: 0 10px;
      }
      .amount {
        text-align: right;
        font-weight: 500;
      }
    }
  }
  .total-wrap {
    margin: 30px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 700;
    span {
      display: inline-block;
    }
    .label-total {
      margin-right: 10px;
    }
    .real-total {
      padding: 5px 15px;
      background-color: $offBlue;
      border: 1px solid $blue;
      min-width: 80px;
      color: $blue;
      text-align: center;
      border-radius: 5px;
    }
  }
  .next-wrap {
    display: flex;
    justify-content: flex-end;
    .next-btn {
      background-color: $offBlue;
      border-color: $offBlue;
      color: $blue;
      font-weight: 700;
      font-size: 14px;
      border-radius: 5px;
    }
  }
}