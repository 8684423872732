@import '../../../colors.scss';

.addresses-wrap-take-order {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  .addresses-list {
    padding: 20px 15px 100px 15px;
    .address-ui {
      margin-bottom: 15px;
    }
  }
  .new-address-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    border-top: 1px solid $borderGray;
    button {
      background-color: $teal;
      border-color: $teal;
      border-radius: 40px;
      height: 50px;
      color: $white;
      font-weight: 700;
    }
  }

  .search-input{
    margin-bottom: 25px;
  }
}