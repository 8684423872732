@import '../../../colors.scss';

.monitor-wrap {
  .number-orders {
    display: flex;
    .number-data {
      text-align: center;
      margin-left: 20px;
    }
  }
  .filters-wrap {
    margin-top: 30px;
    margin-bottom: 30px;
    .filters {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .filter {
        border: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 20px;
        height: 35px;
        padding: 0 15px;
        border-radius: 10px;
        width: 150px;
        font-weight: 700;
        background-color: $offGray;
        &.active {
          background-color: $black;
          color: $white;
        }
        &.on-the-way {
          svg {
            font-size: 20px;
          }
        }
        svg {
          font-size: 16px;
        }
      }
    }
    .take-orders {
      display: flex;
      justify-content: flex-end;
      a {
        border: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 35px;
        padding: 0 15px;
        border-radius: 10px;
        width: 150px;
        font-weight: 700;
        background-color: $teal;
        color: $white;
      }
    }
  }
}

.close-wrap {
  color: $white;
  font-size: 30px;
}

.ant-modal-title {
  font-family: BwModelicaS001 !important;
  font-weight: 800 !important;
  margin: 10px 0 !important;
}
.ant-modal-close-x {
  margin: 10px 0 !important;
  font-size: 20px;
}

.modal-map {
  width: 800px !important;
  .ant-modal-content {
    border-radius: 5px;
    .ant-modal-body {
      border-radius: 5px;
      overflow: hidden;
      padding: 0;
      // width: 900px;
    }
  }
}