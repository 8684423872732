@import '../../../colors.scss';

.close-detail-wrap {
  .loader-wrap {
    padding: 200px 0;
  }
  .main-titles {
    display: flex;
    align-items: flex-end;
  }
  .number-data {
    text-align: center;
  }
  .actions {
    margin: 40px 0;
    display: flex;
    justify-content: flex-end;
    .action {
      display: block;
      margin-left: 20px;
      padding: 9px 15px;
      border-radius: 5px;
      font-weight: 700;
      &.purple {
        background-color: $offPurple;
        color: $purple;
      }
      &.blue {
        background-color: $offBlue;
        color: $blue;
      }
      &.green {
        background-color: $offGreen;
        color: $green;
      }
      &.yellow {
        background-color: $offYellow;
        color: $yellow;
      }
    }
  }
  .link {
    color: $teal;
    font-weight: 800;
    font-family: BwModelicaS001;
    font-size: 20px;
  }
  .details {
    .header-details {
      background-color: #F9F9F9;
      border: 1px solid #F9F9F9;
      padding: 10px 15px;
      font-weight: 700;
      display: flex;
      border-radius: 3px 3px 0 0;
      .denomination {
        flex: 1;
      }
      .amount, .total {
        width: 140px;
      }
      .amount {
        display: inline-block;
        text-align: center;
      }
      .total {
        text-align: right;
      }
    }
    .body-details {
      padding: 15px;
      border-style: solid;
      border-color: #E8E7E7;
      border-width: 0 1px 1px 1px;
      border-radius: 0 0 3px 3px;
      .detail {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .label-detail {
        flex: 1;
      }
      .input-cash, .amount {
        display: inline-block;
        width: 140px;
      }
      .input-cash {
        text-align: center;
      }
      .amount {
        text-align: right;
        font-weight: 500;
      }
    }
  }
  .summary {
    border: 1px solid #E8E7E7;
    border-radius: 5px;
    padding: 15px;
    .detail-summary {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      .bold {
        font-weight: 700;
      }
      .value {
        display: block;
        width: 120px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border: 1px solid #CECECE;
        border-radius: 3px;
        font-weight: 700;
        color: $gray;
        &.gray {
          border-color: $borderGray;
          background-color: #FBFBFC;
          color: $black;
        }
        &.teal {
          border-color: $teal;
          background-color: $offTeal;
          color: $teal;
        }
        &.red {
          // border-color: $red;
          border-color: $borderGray;
          background-color: #FBFBFC;
          // background-color: $offRed;
          color: $red;
        }
        &.blue {
          border-color: $blue;
          background-color: $offBlue;
          color: $blue;
        }
      }
    }
    .horizontal {
      border-top: 1px solid #E8E7E7;
      margin: 20px -15px 20px -15px;
    }
  }
  .comments {
    border: 1px solid #E8E7E7;
    border-radius: 3px;
    padding: 10px;
    width: 100%;
    min-height: 200px;
    margin-top: 20px;
  }
  .orders-detail-close {
    margin-top: 40px;
  }
}