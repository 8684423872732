@import "../../../colors.scss";

.close-settlement-driver {
  .details {
    display: flex;
    margin: 40px 0;
    justify-content: space-around;
    .item-detail {
      text-align: center;
      span {
        display: block;
      }
      .label {
        font-weight: 700;
      }
      .value {
        font-weight: 700;
        font-size: 18px;
      }
      .missing {
        color: $red;
      }
      .surplus {
        color: $teal;
      }
      .balance {
        color: $blue;
      }
    }
  }
  .comments {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    button {
      background-color: $teal;
      border-color: $teal;
    }
  }
}
