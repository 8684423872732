@import '../../../colors.scss';

.product-wrap-take-order {
  .cover {
    height: 180px;
    position: relative;
    background-position: center center;
    background-size: cover;
    background-color: $lightGray;
    button {
      position: absolute;
      top: 20px;
      left: 25px;
      width: 38px;
      height: 38px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      line-height: 0;
      background-color: $white;
    }
  }
  .info-partner {
    padding: 15px;
    font-weight: 500;
    .texts {
      display: flex;
      margin-bottom: 5px;
      span {
        display: block;
      }
      .name {
        font-family: BwModelicaS001;
        font-weight: 800;
        font-size: 20px;
        flex: 1;
      }
    }
    .description {
      color: $darkGray;
      font-weight: 400;
    }
    .category {
      margin-left: 15px;
      margin-top: 5px;
    }
  }
  .categories {
    .category {
      .category-header {
        background-color: #F8F8F8;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        font-family: BwModelicaS001;
        font-weight: 700;
        .notes-wrap {
          .note {
            font-family: Nunito;
            font-weight: 400;
            color: $darkGray;
            font-size: 12px;
          }
        }
      }
      .instructions {
        padding: 15px;
        .input {
          border-radius: 10px;
        }
      }
      .quantity-extra-wrap {
        margin-right: 15px;
        display: flex;
        align-items: center;
        button {
          line-height: 0;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          background-color: $borderGray;
        }
        span {
          display: block;
          margin: 0 5px;
          font-weight: 500;
        }
      }
      .options {
        .option {
          display: flex;
          align-items: center;
          padding: 15px 15px;
          border-bottom: 1px solid #E9EAEB;
          cursor: pointer;
          .radio {
            width: 22px;
            height: 22px;
            border: 1px solid #D2D3D5;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 0;
            margin-right: 15px;
            &.active {
              border-color: $teal;
            }
            .inner-radio {
              background-color: $teal;
              border-radius: 100%;
              width: 16px;
              height: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: $white;
              &.multiple {
                width: 22px;
                height: 22px;
              }
            }
          }
          .name {
            flex: 1;
          }
          .price-wrap {
            display: flex;
            align-items: center;
            .price {
              color: $darkGray;
            }
          }
        }
        .custom-category {
          margin: 15px;
          padding: 10px;
          border: 0.5px solid #D2D3D5;
          border-radius: 5px;
          background-color: #F8F8F8;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          .custom-photo {
            width: 50px;
            height: 50px;
            background-position: center center;
            background-size: cover;
            border-radius: 5px;
          }
          .custom-info {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-left: 10px;
            .name {
              font-weight: 500;
            }
            .subtitle {
              font-weight: 500;
              color: #58B59F;
              font-size: 13px;
            }
          }
          .arrow {
            font-size: 16px;
            color: #2D3240;
          }
        }
      }
    }
  }
  .quantity-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .quantity-controller {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 130px;
      height: 45px;
      border: 1px solid #D2D3D5;
      border-radius: 40px;
      padding: 0 20px;
      font-size: 20px;
      line-height: 0;
      font-weight: 500;
      .operator {
        padding: 5px;
        cursor: pointer;
      }
    }
  }
  .action {
    padding: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    button {
      background-color: $teal;
      color: $white;
      border-color: $teal;
      height: 50px;
      border-radius: 40px;
      font-weight: 700;
      font-size: 16px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      &.disabled {
        background-color: $lightGray;
        border-color: $lightGray;
      }
      .total {
        position: absolute;
        right: 20px;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  .loader-wrap {
    margin-top: 200px;
  }
}
