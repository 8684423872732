.charts-wrap {
  padding-bottom: 80px;
  .chart {
    margin-top: 60px;
    .chart-filters {
      margin-bottom: 40px;
    }
    .loader-wrap {
      height: 200px;
    }
  }
}
