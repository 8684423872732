@import "../../../colors.scss";

.getters-cashflow-wrap {
  .loader-wrap {
    padding: 200px 0;
  }
  .actions {
    margin-top: 20px;
  }
  .number-data {
    text-align: center;
  }
  .export {
    display: block;
    margin-left: 20px;
    padding: 9px 15px;
    border-radius: 5px;
    font-weight: 700;
    background-color: $offGreen;
    color: $green;
  }
  .filters-wrap {
    margin-top: 40px;
    display: flex;
    align-items: center;
    .create-settlement {
      display: flex;
      flex: 1;
      .initial-amount {
        width: 100px;
        margin: 0 15px;
        border-color: #cecece;
        border-radius: 5px;
        text-align: center;
        &.bonus {
          margin-left: 0;
        }
      }
      .open-cashier {
        border-radius: 5px;
        font-size: 14px;
        font-weight: 800;
        background-color: $offGreen;
        color: $green;
        border-color: $offGreen;
      }
    }
  }
  .settlements-drivers {
    margin-top: 40px;
  }
}
