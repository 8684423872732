@import '../../../colors.scss';

.details-drawer {
  .ant-drawer-body {
    padding: 20px 13px 20px 15px;
  }
  .warning-wrap {
    padding-bottom: 20px;
  }
  .ticket-wrap{
    display: flex;
    align-items: center;
    line-height: 0;
    i {
      display: inline-block;
      margin-right: 10px;
    }
    .app {
      color: $blue;
    }
    .call {
      color: $red;
    }
  }
  .label-tunicaragua {
    display: block;
    width: 125px;
    margin-bottom: 10px;
  }
  .action {
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    line-height: 0;
  }
  .phone-wrap {
    background-color: $offTeal;
    color: $green;
  }
  .compass-wrap {
    background-color: $offYellow;
    color: $yellow;
  }
  .list-wrap {
    background-color: $offRed;
    color: $red;
  }
  .cancel-wrap {
    font-size: 22px;
  }
  .section {
    padding: 15px;
    border: 1px solid $borderGray;
    border-radius: 15px;
  }
  .partner-info {
    background-image: url('../assets/background.png');
    height: 160px;
    background-size: cover;
    background-position: center center;
    position: relative;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    .close-btn {
      position: absolute;
      top: 5px;
      left: 5px;
      border: none;
      padding: 0;
      background-color: transparent;
      svg {
        color: $white;
        font-size: 35px;
        text-shadow: 0 3px 6px rgba($color: $black, $alpha: 0.65);
      }
    }
    .info {
      display: flex;
      width: 100%;
      .logo {
        width: 60px;
        height: 60px;
        background-size: cover;
        border-radius: 100%;
        border: 2px solid $white;
        margin-right: 20px;
      }
      .names {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $white;
        flex: 1;
        .name {
          font-family: BwModelicaS001;
          font-weight: 800;
          font-size: 24px;
        }
        .branch {
          font-weight: 500;
          font-size: 16px;
          margin-top: -3px;
        }
      }
      .phone-wrap {
        margin-top: 8px;
      }
    }
  }
  .ticket-info {
    display: flex;
    padding: 10px 15px;
    margin: 20px 0;
    font-family: BwModelicaS001;
    font-weight: 700;
    font-size: 18px;
    justify-content: space-between;
    .ticket {
      color: $yellow;
      text-transform: uppercase;
    }
  }
  .user-info {
    & > div:first-of-type {
      align-items: center;
      margin-bottom: 10px;
      flex-direction: row;
      display: flex;
      flex-wrap: wrap;
      .actions {
        display: flex;
        button {
          margin-left: 10px;
        }
      }
      .name {
        flex: 1;
        font-family: BwModelicaS001;
        font-weight: 800;
        font-size: 18px;
      }
    }
    .label {
      font-weight: 800;
      font-size: 10px;
    }
    p {
      color: $gray;
      font-weight: 500;
      &:last-child {
        margin: 0;
      }
    }
  }
  .steps {
    margin-top: 20px;
  }
}