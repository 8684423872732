@import '../../../colors.scss';

.users-editor {
  .back {
    min-width: 35px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $darkBlue;
    border-radius: 100%;
    color: $white;
    margin-right: 15px;
    font-size: 25px;
    line-height: 18px;
  }
  .main-titles {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .form-editor {
    margin-top: 30px;
    .ant-upload-select-picture-card {
      width: 100%;
      height: 250px;
      border-radius: 20px;
      background-color: $borderGray;
      border-color: $gray;
      overflow: hidden;
    }
    .placeholder-img {
      svg {
        width: 50px;
        height: 50px;
        color: $gray;
      }
    }
    .photo {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
    }
    .label {
      font-weight: 700;
      font-size: 11px;
      letter-spacing: 1px;
    }
    .input, .ant-select-selector {
      border-radius: 12px;
      height: 45px;
      border: none;
      padding-left: 20px;
      padding-right: 20px;
      border: 1px solid #CECECE;
    }
    .textarea {
      height: auto !important;
    }
    .ant-select-selector {
      border-radius: 12px !important;
      border: 1px solid #CECECE !important;
      height: 45px !important;
      display: flex;
      align-items: center;
    }
    .actions {
      height: 75px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .btn {
        height: 40px;
        width: 110px;
        border: none;
        font-weight: 700;
        border-radius: 12px;
      }
      .active-btn {
        background-color: $offGray;
        color: $black;
      }
      .save-btn {
        background-color: $offBlue;
        color: $blue;
      }
    }
    .disclaimer {
      font-weight: 500;
      color: $gray;
    }
    .sub-actions {
      margin-top: 40px;
      .btn {
        border: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        width: 180px;
        border-radius: 10px;
        font-weight: 700;
        svg {
          margin-left: 15px;
        }
      }
      .btn-map {
        background-color: $offYellow;
        color: $yellow;
        margin-bottom: 20px;
      }
      .btn-lock {
        background-color: $offRed;
        color: $red;
      }
    }
  }
}