@import '../../../colors.scss';

.header-drawer {
  position: relative;
  width: 400px;
  height: 100%;
  text-align: center;
  padding-top: 6px;
  padding-bottom: 6px;
  .title {
    font-size: 20px;
    color: $teal;
    font-family: BwModelicaS001;
    font-weight: 800;
  }
  .close {
    position: absolute;
    left: -10px;
    margin-top: -5px;
    background-color: transparent;
    i {
      font-size: 32px;
    }
  }
}

.cashflow-wrap {
  .loader-wrap {
    padding: 200px 0;
  }
  .number-orders {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .number-data {
      text-align: center;
      margin-left: 40px;
    }
    .current-cash-wrap {
      display: flex;
      flex-direction: column;
      font-weight: 700;
      .label-cash {
        font-size: 12px;
      }
      .current-cash {
        margin-top: 5px;
        display: block;
        padding: 8px 20px;
        background-color: $offGreen;
        color: $green;
        text-align: center;
        font-size: 16px;
        border-radius: 5px;
      }
    }
  }
  .actions-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 35px 0;
    .initial-wrap {
      .label-cash {
        display: block;
        font-weight: 700;
        font-size: 10px;
        margin-bottom: 2px;
      }
      .initial-amount-cash {
        display: flex;
        align-items: center;
        .initial-amount {
          width: 120px;
          margin-right: 5px;
          border-color: #CECECE;
          border-radius: 5px;
          text-align: center;
        }
        .open-cashier {
          border-radius: 5px;
          font-size: 14px;
          font-weight: 800;
          background-color: $offGreen;
          color: $green;
          border-color: $offGreen;
        }
      }
    }
    .actions {
      display: flex;
      .action {
        display: block;
        margin-left: 20px;
        padding: 9px 15px;
        border-radius: 5px;
        font-weight: 700;
        &.purple {
          background-color: $offPurple;
          color: $purple;
        }
        &.blue {
          background-color: $offBlue;
          color: $blue;
        }
        &.green {
          background-color: $offGreen;
          color: $green;
        }
        &.yellow {
          background-color: $offYellow;
          color: $yellow;
        }
      }
    }
  }
  .cashflow {
    font-weight: 700;
    .summary {
      display: flex;
      margin-bottom: 25px;
      justify-content: space-between;
      .summary-item {
        // margin-right: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .label-summary {
          margin-right: 5px;
          color: $darkGray;
          font-size: 16px;
        }
        .value-summary {
          font-size: 20px;
          // font-family: BwModelicaS001;
          font-weight: 800;
        }
      }
    }
  }
}