@import '../../../colors.scss';

.order-detail-take-order {
  overflow: hidden;
  .partner-info {
    border-top: 1px solid $borderGray;
    border-bottom: 1px solid $borderGray;
    padding: 15px;
    display: flex;
    .partner-logo {
      width: 55px;
      min-width: 55px;
      height: 55px;
      margin-right: 15px;
      background-color: $white;
      border-radius: 100%;
      border: 1px solid $borderGray;
      display: flex;
      align-items: center;
      justify-content: center;
      background-position: center center;
      background-size: cover;
      img {
        width: 22px;
        margin-left: -2px;
      }
    }
    .partner-name {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-right: 15px;
      .name {
        font-weight: 800;
        font-family: BwModelicaS001;
      }
      .type { 
        font-size: 12px;
      }
    }
    .edit-btn {
      color: $red;
      cursor: pointer;
      padding: 5px;
      margin-right: -5px;
      margin-top: -5px;
    }
  }
  .detail {
    padding: 15px;
    border-bottom: 1px solid $borderGray;
    &.empty {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      i {
        line-height: 5px;
      }
    }
    .detail-wrap {
      .label-detail {
        font-weight: 700;
      }
      .details-list {
        margin-top: 10px;
        .product-wrap {
          .product-header {
            display: flex;
            align-items: center;
            margin-left: -84px;
            &.edit {
              margin-left: 0;
            }
            .quantity {
              width: 25px;
              height: 25px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #D2D3D5;
              background-color: $white;
              border-radius: 5px;
              color: $red;
              margin-right: 10px;
            }
            .name {
              flex: 1;
            }
            .price {
              margin-left: 15px;
            }
          }
          .categories {
            margin-top: 10px;
            .category {
              margin-bottom: 10px;
              padding-left: 35px;
              .label-category {
                font-weight: 500;
                font-size: 12px;
                color: $darkGray;
              }
              .extra-wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;
                // color: $gray;
              }
            }
          }
          .actions {
            display: flex;
            margin-right: 15px;
            button {
              width: 30px;
              height: 30px;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: 0;
              font-size: 12px;
            }
            .delete {
              margin-right: 10px;
              background-color: $offRed;
              color: $red;
            }
            .edit {
              background-color: $offBlue;
              color: $blue;
            }
          }
        }
      }
    }
  }
}