@import '../../../colors.scss';

.drivers-editor {
  .form-editor {
    margin-top: 30px;
    position: relative;
    .permissions {
      position: absolute;
      right: 0;
      top: -80px;
      display: flex;
      & > div {
        text-align: center;
        margin-left: 40px;
      }
      span {
        display: block;
      }
    }
    .ant-upload-select-picture-card {
      width: 100%;
      height: 250px;
      border-radius: 20px;
      background-color: $borderGray;
      border-color: $gray;
      overflow: hidden;
    }
    .placeholder-img {
      svg {
        width: 50px;
        height: 50px;
        color: $gray;
      }
    }
    .photo {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
    }
    .label {
      font-weight: 700;
      font-size: 11px;
      letter-spacing: 1px;
    }
    .input, .ant-select-selector {
      border-radius: 12px;
      height: 45px;
      border: none;
      padding-left: 20px;
      padding-right: 20px;
      border: 1px solid #CECECE;
    }
    .ant-select-selector {
      border-radius: 12px !important;
      border: 1px solid #CECECE !important;
      height: 45px !important;
      display: flex;
      align-items: center;
    }
    .actions {
      height: 75px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .btn {
        height: 40px;
        width: 110px;
        border: none;
        font-weight: 700;
        border-radius: 12px;
      }
      .active-btn {
        background-color: $offGray;
        color: $black;
      }
      .save-btn {
        background-color: $offBlue;
        color: $blue;
      }
    }
    .disclaimer {
      font-weight: 500;
      color: $gray;
    }
    .sub-actions {
      margin-top: 40px;
      .btn {
        border: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        width: 180px;
        border-radius: 10px;
        font-weight: 700;
        svg {
          margin-left: 15px;
        }
      }
      .btn-map {
        background-color: $offYellow;
        color: $yellow;
        margin-bottom: 20px;
      }
      .btn-lock {
        background-color: $offRed;
        color: $red;
        margin-bottom: 20px;
      }
      
      .btn-available {
        background-color: $offBlue;
        color: $blue;
        margin-bottom: 20px;
        i {
          font-size: 16px;
          display: block;
          margin-top: -3px;
        }
      }
    }
  }
}