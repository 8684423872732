@import '../../../colors.scss';

.user-register-take-order {
  .form-register {
    border: 1px solid $borderGray;
    padding: 20px;
    border-radius: 15px;
    font-weight: 500;
    .input-notes {
      margin-bottom: 5px;
    }
    .input {
      border: 1px solid $borderGray;
      border-radius: 5px;
      font-size: 14px;
    }
    .label {
      margin-bottom: 10px;
      display: block;
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
    .submit {
      width: 100px;
      height: 40px;
      background-color: $offBlue;
      border-color: $offBlue;
      color: $blue;
      font-weight: 700;
      border-radius: 10px;
    }
  }
}