@import "~antd/dist/antd.css";
@import "colors.scss";

@font-face {
  font-family: BwModelicaS001;
  src: url(assets/fonts/BwModelicaS001/BwModelicaSS01-ExtraBold.otf);
  font-weight: 800;
}

@font-face {
  font-family: BwModelicaS001;
  src: url(assets/fonts/BwModelicaS001/BwModelicaSS01-Bold.otf);
  font-weight: 700;
}

@font-face {
  font-family: Nunito;
  src: url(assets/fonts/Nunito/Nunito-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Nunito;
  src: url(assets/fonts/Nunito/Nunito-SemiBold.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Nunito;
  src: url(assets/fonts/Nunito/Nunito-Bold.ttf);
  font-weight: 800;
}

body {
  font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  width: 100vw;
  height: 100vh;

  button {
    cursor: pointer;
    border: none;
    &:focus {
      outline: none;
    }
  }

  .align-right {
    text-align: right;
  }

  .dashboard-wrapper {
    height: 100vh;
  }

  .cash-input-wrap {
    display: flex;
    justify-content: center;
    .btn-save {
      background-color: $teal;
      border-color: $teal;
      color: $white;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 0 3px 3px 0;
    }
    .input-cashflow {
      border-radius: 3px 0 0 3px;
    }
  }

  .loader-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .main-titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-family: BwModelicaS001;
      font-weight: 800;
      font-size: 38px;
      margin-bottom: 0;
    }
    .subtitle {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 0;
      text-transform: capitalize;
    }
  }

  .table-orders {
    font-weight: 700;
    .order-detail {
      padding: 5px 15px;
      color: $purple;
      background-color: $offPurple;
      border-radius: 5px;
      margin: 0 auto;
      font-weight: 700;
      display: inline-block;
    }
    .normal {
      font-weight: 400;
    }
    .input-cashflow {
      color: $teal;
      font-weight: 700;
      text-align: center;
      border: 1px solid $teal;
    }
    .input-card {
      background-color: $offGreen;
    }
    .input-cashout {
      border-color: $pink;
      color: $pink;
    }
    .red {
      color: $red;
    }
    .yellow {
      color: $yellow;
    }
    .blue {
      color: $blue;
    }
    .teal {
      color: $teal;
    }
    .icon-o {
      font-size: 20px;
    }
  }
}
