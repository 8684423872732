@import '../../../colors.scss';

.take-orders-wrap {
  .client-wrap-take-order {
    .title-wrap {
      display: flex;
      flex: 1;
      align-items: center;
      .title {
        font-weight: 700;
        font-size: 20px;
        font-family: BwModelicaS001;
      }
    }
    .input {
      width: 240px !important;
      border-color: $borderGray;
    }
    .ant-input-search-button {
      background-color: $teal;
      border-color: $teal;
    }
  }
  .detail-wrap {
    background-color: $offGray;
    border-radius: 10px;
    .header-detail {
      font-family: BwModelicaS001;
      font-weight: 800;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
    }
  }
  .results {
    padding: 15px 15px 0 15px;
    border-bottom: 10px solid $borderGray;
    .item {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      margin-bottom: 15px;
      color: #BDC1CA;
      &.total {
        color: $black;
      }
    }
  }
  .tunicaragua-wrapper {
    padding: 30px 0;
    margin-top: -10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      display: block;
      font-weight: 500;
    }
  }
  .extra-info {
    margin-bottom: 15px;
  }
  .action {
    padding: 25px 15px;
    button {
      height: 50px;
      background-color: $teal;
      border-color: $teal;
      font-weight: 700;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .price {
        position: absolute;
        // top: 10px;
        right: 20px;
        font-size: 12px;
      }
    }
  }
  .replace-order {
    padding: 20px 15px 10px 15px;
    & > span {
      display: block;
      margin-bottom: 15px;
      font-weight: 700;
    }
    .replace-order-wrap {
      display: flex;
      input {
        margin-right: 15px;
      }
      .search-btn {
        background-color: $teal;
        border-color: $teal;
      }
    }
    .replaced-order {
      display: flex;
      background-color: $offRed;
      padding: 15px;
      border: 1px solid $red;
      border-radius: 7px;
      align-items: center;
      justify-content: space-between;
      font-weight: 700;
      text-transform: uppercase;
      i {
        font-size: 24px;
        display: flex;
        color: $red;
        cursor: pointer;
        padding: 2px;
      }
    }
  }
}