$black: #020815;
$white: #FFF; 
$red: #ED243D;
$pink: #FD8493;
$teal: #5EC1A9;
$yellow: #F9A41A;
$blue: #3357E3;
$purple: #9652F2;
$offYellow: #FDF8E5;
$offPurple: #F4EDFE;
$gray: #9EA0A5;
$darkGray: #929396;
$green: #5A9075;
$offGreen: #BBEFE7;
$lightGray: #D3D3DE;
$offTeal: #E5FDF1;
$offGray: #F4F5F7;
$offRed: #FEF2F4;
$offBlue: #F1F5FE;
$offGreen: #EEF9F6;
$darkBlue: #15234C;
$borderGray: #EAECF0;
