@import "../../../colors.scss";



.summations-body{
    margin-top:50px;
    .ant-table{
        margin-top:25px  !important;
    }
}
.export {
    display: block;
    margin-left: 20px;
    padding: 9px 15px;
    border-radius: 5px;
    font-weight: 700;
    background-color: $offGreen;
    color: $green;
  }

  .actions{
    display: flex;
    justify-content: space-between;
  }



  .close-detail {
    display: inline-block;
    padding: 6px 15px;
    color: $purple;
    background-color: $offPurple;
    border-radius: 5px;
    margin: 0 auto;
    font-weight: 700;
  }
  .detail{
    margin-top:20px;
  }
  .header-detail{
    display: grid;
    grid-template-columns: 1fr 140px

  }
  .driver{
    font-weight: 900;
    font-size: x-large;
  }
  .driver p {
    font-size: 12px;
    margin-bottom: 2px;
    color:#CECECE;
  }
  .block-detail{
    display: grid;
    grid-template-columns: repeat(3,1fr) ;
  }
   
  .detail-summary{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   
    .title{
      font-weight: 700;
    }
    
    b{
      font-size: 18px;
    }
    .value{
      display: block;
      width: 120px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border: 1px solid #CECECE;
      border-radius: 3px;
      font-weight: 700;
     
    }
    .balance{
      display: block;
      width: 120px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border: 1px solid #CECECE;
      border-radius: 3px;
      font-weight: 700;
      border-color: #5EC1A9;
      background-color: #E5FDF1;
      color: #5EC1A9;
      font-size: 18px;
    }
    
  }
  .plus{
      background-color: #DCE6EF;
      display: block;
      width: 120px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border: 1px solid #CECECE;
      border-radius: 3px;
      border-color: #CECECE;
      margin:2px 0px
  }
  .less{
    background-color: #F1DCDB;
    display: block;
    width: 120px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border: 1px solid #CECECE;
    border-radius: 3px;
    border-color: #CECECE;
    margin:2px 0px
}
  .container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 25px;
    margin-top: 8px;
    margin-bottom: 8px;
    p{
      margin-bottom: 0px;
    }
  }
  .container-details{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 25px;
    margin-top: 8px;

    p{
      margin-bottom: 8px;
    }
  }
  .divider{
    border:2px solid rgba(0, 0, 0, 0.06);
  }