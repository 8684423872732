@import '../../../colors.scss';

.address-order-take-order {
  padding: 15px;
  border-bottom: 1px solid $borderGray;
  .address-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    span {
      font-weight: 700;
    }
  }
  .empty-address {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    font-weight: 500;
    button {
      width: 22px;
      height: 22px;
      border-radius: 100%;
      background-color: $teal;
      color: $white;
      font-size: 20px;
      line-height: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .notes-wrap {
    margin-top: 25px;
    margin-bottom: 10px;
    .notes {
      background-color: #E1E3E6;
      font-weight: 500;
      border-color: #E1E3E6;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
