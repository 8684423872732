@import '../../../colors.scss';

.FeedSortUI {
  .partners-wrap {
    .check-btn {
      border-color: $teal;
      background-color: $teal;
      float: right;
    }

    .table-data {
      margin-top: 40px;
    }

    .ant-table-row {
      cursor: pointer;
    }

    .banner-form {
      margin: 10px 0 0 0;
      padding: 20px 20px 20px 20px;
      background-color: #FAFAFA;
      border-radius: 10px;

      .banner-image {
        margin-top: -10px;
        height: 150px;
        // width: 225px;
        background-color:#FFFFFF;
        border-radius: 5px;
        // background-image: url('https://get-assets-cdn.nyc3.digitaloceanspaces.com/promotions/get_coffee.png');
        background-size: cover;
        background-position: center;
      }

    }

    .form {
      margin: 10px 0 0 0;
      padding: 20px 20px 0px 20px;
      background-color: #FAFAFA;
      border-radius: 10px;

      .save {
        right: 0;
        position: absolute;
        margin-top: 30px;
        margin-right: 10px;
      }
    }

    // margin-top: px;
    .checks {
      margin-bottom: 10px;
      margin-top: 5px;
      width: 350px;
    }
    .active-switch {
      margin-left: 16px;
      div {
        padding-top: 10px;
      }
    }

    .category-image {
      width: 30px;
    }

    .ctg-img {
      width: 30px !important;
      // display: none;
    }

    .categories {
      padding-right: 5px;
      margin-top: 20px;
    }

  }
}