@import '../../../colors.scss';

.map-wrapper {
  position: relative;
  .filter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
    height: 80px;
  }
  .marker {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &.driver {
      .wrap {
        border-color: $yellow;
      }
      .point {
        background-color: $yellow;
      }
    }
    &.user {
      .wrap{
        border-color: #15234C;
      }
      .point {
        background-color: $teal;
      }
    }
    .wrap {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 1px solid $red;
      padding: 2px;
      .image {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-size: cover;
        background-position: center center;
        background-color: $white;
      }
      .user-wrap {
        display: flex;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: $teal;
        color: $white;
        align-items: center;
        justify-content: center;
        font-size: 14px;
      }
    }
    .point {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: $red;
      margin-top: 5px;
    }
    .info {
      background-color: $white;
      padding: 10px 15px;
      border-radius: 40px;
      width: 80px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 8px;
      font-family: BwModelicaS001;
      font-size: 8px;
      font-weight: 700;
      box-shadow: 0 3px 6px rgba(0,0,0,0.05);
      margin-bottom: 10px;
      text-align: center;
    }
  }
}