@import '../../../colors.scss';

.payment-take-order {
  padding: 15px;
  .payment-header {
    font-weight: 700;
    margin-bottom: 20px;
  }
  .payments {
    .payment {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
      svg, .card {
        margin-right: 15px;
        margin-bottom: 15px;
      }
      .card {
        width: 45px;
        height: 34px;
      }
      .payment-info {
        flex: 1;
        border-bottom: 1px solid $borderGray;
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        margin-right: -15px;
        padding-right: 15px;
        .texts {
          flex: 1;
          span {
            display: block;
          }
          .name {
            font-weight: 700;
          }
          .description {
            font-size: 12px;
          }
        }
      }
      .check-wrap {
        width: 23px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #808080;
        color:#808080;
        border-radius: 100%;
        font-size: 16px;
        i {
          line-height: 16px;
        }
        &.active {
          background-color: $teal;
          border-color: $teal;
          color: $white;
        }
      }
    }
  }
  .cashback {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    span {
      display: block;
      margin-bottom: 10px;
      font-weight: 700;
    }
    .input {
      width: 80px;
      background-color: #E1E3E6;
      color: $gray;
      font-weight: 500;
      text-align: center;
    }
  }
  .form-card {
    .input-wrap {
      position: relative;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .cardImg {
        width: 35px;
        height: 23.64px;
        position: absolute;
        z-index: 20;
        top: 8px;
        left: 10px;
      }
      .horizontal {
        display: flex;
        align-items: center;
      }
      .divider {
        display: block;
        font-weight: 500;
        font-size: 24px;
        margin: 0 20px;
      }
      .input-label {
        display: block;
        margin-bottom: 5px;
        font-weight: 500;
      }
      .input-form {
        background-color: #E1E3E6;
        border-radius: 5px;
        border: 1px solid #D2D3D5;
        display: flex;
        font-weight: 500;
        font-size: 16px;
        &:focus {
          outline: none !important;
          border: none;
        }
        &.number-card {
          padding-left: 60px;
        }
      }
    }
  }
}