@import "../../../colors.scss";

.dashboard-wrapper {
  background-color: $white;
  position: relative;
  overflow-y: scroll;
  .sider-wrap {
    border-right: 1px solid $lightGray;
    .sider {
      overflow-y: scroll;
      height: 100vh;
    }
    .logo-wrap {
      height: 90px;
      display: flex;
      align-items: center;
      padding-left: 30px;
      .logo {
        width: 60px;
      }
    }
    .version-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px 0;
      .version {
        text-align: center;
        color: $gray;
        font-weight: 500;
      }
    }
    .nav {
      color: $gray;
      font-weight: 500;
      border-right: 0;
      .item-nav {
        line-height: 55px !important;
        height: 55px !important;
        display: flex;
        align-items: center;
        &:hover {
          color: $yellow;
        }
        &:after {
          border-color: $yellow;
        }
        &.ant-menu-item-selected {
          background-color: rgba($yellow, 0.1) !important;
          color: $yellow;
          font-weight: 700;
          &:after {
            border-color: $yellow;
          }
        }
        &.cash-wrap {
          i {
            font-size: 20px;
          }
        }
        .ant-menu-title-content {
          margin-left: 0;
        }
        svg {
          margin-right: 20px;
          font-size: 18px;
        }
        i {
          margin-right: 20px;
          margin-top: -5px;
          font-size: 18px;
          display: inline-block;
        }
        .cash-icon {
          margin-top: 0;
        }
        .cash-coin-icon {
          margin-top: 8px;
        }
        .bicycle-icon {
          font-size: 22px !important;
          margin-top: 4px;
        }
      }
    }
  }
  .header,
  .content {
    background-color: $white;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px !important;
    .country {
      font-weight: 700;
      display: flex;
      align-items: center;
      font-size: 14px;
    }
    .flag {
      font-size: 26px;
      display: flex;
      margin-right: 5px;
    }
    .account {
      display: flex;
      align-items: center;
    }
    .username {
      font-weight: 700;
      margin-right: 20px;
    }
    .button-profile {
      background-color: $offTeal;
      border: none;
      width: 35px;
      height: 35px;
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
  }
  .content {
    padding: 20px 40px;
    flex: 1;
    overflow-y: scroll;
  }
}
