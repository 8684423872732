@import '../../../colors.scss';

.drivers-wrap {
  tr {
    cursor: pointer !important;
  }
  .actions {
    margin: 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 35px;
      padding: 0 12px;
      border-radius: 10px;
      font-weight: 700;
      svg {
        margin-left: 15px;
      }
    }
    .main-actions {
      display: flex;
      align-items: center;
      button {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
    .map-btn {
      background-color: $offYellow;
      color: $yellow;
    }
    .new-btn {
      background-color: $offBlue;
      color: $blue;
    }
    .deactive {
      background-color: $offGray;
      color: $black;
    }
  }
  .drivers-info {
    .center-tag {
      display: flex;
      justify-content: center;
      .ant-tag {
        margin-right: 0;
        font-weight: 700;
        padding: 4px 10px;
        border-radius: 6px;
        font-size: 10px;
      }
    }
  }
}