@import '../../../colors.scss';

.settlements-wrap {
  .loader-wrap {
    padding: 200px 0;
  }
  .label-cash {
    display: block;
    font-weight: 700;
    font-size: 10px;
    margin-bottom: 2px;
  }
  .input-confirmation {
    display: flex;
    align-items: center;
  }
  .number-confirmation {
    width: 150px;
    margin-right: 5px;
    border-color: #CECECE;
    border-radius: 5px;
  }
  .btn-confirmation {
    border-radius: 5px;
    font-size: 14px;
    font-weight: 800;
    background-color: $offBlue;
    color: $blue;
    border-color: $offBlue;
  }
  .description {
    width: 300px;
  }
  .actions-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 35px 0;
    .actions {
      display: flex;
      .action {
        display: block;
        margin-left: 20px;
        padding: 9px 15px;
        border-radius: 5px;
        font-weight: 700;
        &.purple {
          background-color: $offPurple;
          color: $purple;
        }
        &.blue {
          background-color: $offBlue;
          color: $blue;
        }
        &.green {
          background-color: $offGreen;
          color: $green;
        }
      }
    }
    .filters-wrap {
      display: flex;
      align-items: center;
      .select-type {
        width: 150;
        margin: 0 20px;
        display: inline-block;
      }
      .filter-btn {
        background-color: $blue;
        border-color: $blue;
        font-weight: 500;
      }
    }
  }
  .pendings {
    font-weight: 700;
    .settlement-detail {
      padding: 6px 15px;
      color: $purple;
      background-color: $offPurple;
      border-radius: 5px;
      margin: 0 auto;
      font-weight: 700;
    }
    .box {
      width: 75px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid;
      border-radius: 5px;
      margin: 0 auto;
      &.yellow {
        background-color: $offYellow;
      }
      &.blue {
        background-color: $offBlue;
      }
    }
    .normal {
      font-weight: 400;
    }
    .input-cashout {
      border-color: $pink;
      color: $pink;
    }
    .yellow {
      color: $yellow;
    }
    .blue {
      color: $blue;
    }
    .teal {
      color: $teal;
    }
    .icon-o {
      font-size: 20px;
    }
  }
}