@import '../../../colors.scss';

.menu-wrap-take-order {
  .cover {
    height: 180px;
    position: relative;
    background-position: center center;
    background-size: cover;
    button {
      position: absolute;
      top: 20px;
      left: 25px;
      width: 38px;
      height: 38px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      line-height: 0;
      background-color: $white;
    }
  }
  .info-partner {
    padding: 15px;
    display: flex;
    font-weight: 500;
    .texts {
      flex: 1;
      span {
        display: block;
      }
      .name {
        font-family: BwModelicaS001;
        font-weight: 800;
        font-size: 20px;
      }
      .disclaimer {
        color: $darkGray;
        display: block;
        margin-top: 10px;
        font-size: 12px;
      }
    }
    .category {
      margin-top: 5px;
    }
  }
  .search-wrap {
    padding: 15px;
    margin-top: -15px;
    input {
      border-radius: 5px;
    }
  }
  .categories {
    .category {
      .category-header {
        background-color: #F8F8F8;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        font-family: BwModelicaS001;
        font-weight: 800;
      }
      .product {
        display: flex;
        padding: 10px 15px;
        border-bottom: 1px solid $borderGray;
        cursor: pointer;
        .product-info {
          flex: 1;
          padding-right: 15px;
          span {
            display: block;
          }
          .name {
            font-weight: 700;
            margin-bottom: 5px;
          }
          .description {
            color: $darkGray;
            margin-bottom: 5px;
          }
          .price {
            font-weight: 500;
          }
        }
        .photo {
          width: 70px;
          height: 70px;
          background-color: $borderGray;
          border-radius: 5px;
          background-size: cover;
          background-position: center center;
        }
      }
    }
  }
  .loader-wrap {
    margin-top: 200px;
  }
}
