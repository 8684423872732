@import '../../../colors.scss';

.orders-wrap {
  .loader-wrap {
    padding: 200px 0;
  }
  .export-btn {
    color: $teal;
    background-color: $offTeal;
    border-color: $offTeal;
    margin-right: 30px;
    &:hover, &:focus {
      color: $teal;
      background-color: $offTeal;
      border-color: $offTeal;
      margin-right: 30px;
    }
  }
  .filters-wrap {
    display: flex;
    .ant-input-search-with-button {
      width: 240px;
      margin-right: 40px;
      button {
        background-color: $teal;
        border-color: $teal;
      }
    }
  }
}