@import '../../../colors.scss';

.close-cashier-wrap {
  overflow-y: scroll;
  .summary {
    border: 1px solid #E8E7E7;
    border-radius: 5px;
    padding: 15px;
    .detail-summary {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      .bold {
        font-weight: 700;
      }
      .value {
        display: block;
        width: 80px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border: 1px solid #CECECE;
        border-radius: 3px;
        font-weight: 700;
        color: $gray;
        &.gray {
          border-color: $borderGray;
          background-color: #FBFBFC;
          color: $black;
        }
        &.teal {
          border-color: $teal;
          background-color: $offTeal;
          color: $teal;
        }
        &.red {
          // border-color: $red;
          border-color: $borderGray;
          background-color: #FBFBFC;
          // background-color: $offRed;
          color: $red;
        }
        &.blue {
          border-color: $blue;
          background-color: $offBlue;
          color: $blue;
        }
      }
    }
    .horizontal {
      border-top: 1px solid #E8E7E7;
      margin: 20px -15px 20px -15px;
    }
  }
  .commentary {
    margin: 20px 0 40px 0;
    span {
      font-weight: 700;
      display: block;
      margin-bottom: 5px;
    }
    .text {
      border-radius: 5px;
      border-color: #E8E7E7;
    }
  }
  .cash-wrap {
    margin: 40px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 700;
    .cash {
      display: block;
      width: 80px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border: 1px solid $blue;
      border-radius: 3px;
      margin-left: 15px;
      color: $blue;
      background-color: $offBlue;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
      font-weight: 700;
      font-size: 14px;
    }
    .print {
      color: $purple;
      background-color: $offPurple;
      border-color: $offPurple;
    }
    .close {
      color: $blue;
      background-color: $offBlue;
      border-color: $offBlue;
    }
  }
}