@import '../../../colors.scss';

.step-detail {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  &.disabled {
    .actions {
      .action {
        background-color: $offGray;
        color: $gray;
      }
    }
    .action {
      .send {
        color: $gray !important;
        background-color: $offGray !important;
      }
    }
    .step-number {
      background-color: $offRed;
      color: $black;
      &.yellow {
        background-color: $offYellow;
      }
      &.teal {
        background-color: $offTeal;
      }
    }
    .action-step {
      color: $gray !important;
      background-color: $offGray !important;
    }
  }
  .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: $red;
    font-family: BwModelicaS001;
    color: $white;
    font-weight: 700;
    border-radius: 100%;
    margin-right: 15px;
    margin-top: 7px;
    &.yellow {
      background-color: $yellow;
    }
    &.teal {
      background-color: $teal;
    }
  }
  .section {
    flex: 1;
    max-width: 372px;
    .main {
      display: flex;
      margin-bottom: 5px;
      &.tuNicaragua {
        align-items: center;
        margin-bottom: 0;
      }
      .info {
        flex: 1;
        span {
          display: block;
        }
        .title {
          font-weight: 700;
        }
      }
      .actions {
        // width: 150px;
        display: flex;
        justify-content: flex-end;
        margin-left: 10px;
        button {
          width: 36px;
          min-width: 36px;
          height: 36px;
          align-items: center;
          justify-content: center;
          display: flex;
        }
        .action {
          margin-top: 0;
        }
        .phone-wrap {
          margin-left: 10px;
        }
        .disabled {
          background-color: $offGray;
          color: $gray;
        }
      }
    }
    .subtitle {
      font-weight: 500;
      color: $darkGray;
    }
    .label {
      display: block;
      margin-top: 20px;
      margin-bottom: 5px;
      font-weight: 800;
      font-size: 10px;
    }
    .action {
      display: flex;
      width: 100%;
      .avatar-driver {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border: 1px solid $borderGray;
        background-color: $borderGray;
        border-radius: 100%;
        .photo {
          width: 100%;
          height: 100%;
          background-size: cover;
          border-radius: 100%;
        }
      }
      .action-step {
        display: flex;
        align-items: center;
        padding-left: 10px;
      }
      .select, .action-step {
        width: 245px;
      }
      .driver-select {
        width: 195px;
      }
      .ant-select-selector, .action-step {
        border-radius: 12px;
        font-weight: 500;
        border: 1px solid $borderGray;
        height: 40px;
      }
      .send {
        background-color: $teal;
        color: $white;
        width: 100px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        border-radius: 15px;
        margin-left: 10px;
        font-weight: 700;
      }
      .disabled {
        border: none;
        background-color: $offGray;
      }
    }
  }
}