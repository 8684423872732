@import '../../../colors.scss';

.address-editor-take-order {
  .map-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: $lightGray;
    border-radius: 10px;
  }
  .marker {
    position: absolute;
    margin-top: -10px;
  }
  .label{
    font-weight: 700;
  }
  .input {
    border: 1px solid $borderGray;
    border-radius: 5px;
  }
  .icons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .btn-icon {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $borderGray;
      cursor: pointer;
      // margin-right: 20px;
      .active {
        color: $teal;
      }
    }
  }
  .action {
    margin-top: 30px;
    button {
      background-color: $teal;
      font-weight: 500;
      color: $white;
      border-radius: 40px;
      height: 45px;
      border-color: $teal;
    }
  }
}