@import '../../../colors.scss';

.history-settlements-wrap {
  margin-top: 30px;
  font-weight: 700;
  .close-detail {
    display: inline-block;
    padding: 6px 15px;
    color: $purple;
    background-color: $offPurple;
    border-radius: 5px;
    margin: 0 auto;
    font-weight: 700;
  }
  .normal {
    font-weight: 400;
  }
  .red {
    color: $red;
  }
  .yellow {
    color: $yellow;
  }
  .blue {
    color: $blue;
  }
  .teal {
    color: $teal;
  }
}
