@import '../../../colors.scss';

.reports-wrap {
  .loader-wrap {
    padding: 200px 0;
  }
  .actions {
    margin-top: 20px;
  }
  .filters-wrap {
    .ant-input-search-with-button {
      width: 100%;
      button {
        background-color: $teal;
        border-color: $teal;
      }
    }
    .export-btn {
      border-color: $teal;
      background-color: $teal;
      float: right;
    }
  }
}