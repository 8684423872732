@import '../../../colors.scss';

.closes-wrap {
  .loader-wrap {
    padding: 200px 0;
  }
  .number-data {
    text-align: center;
  }
  .main-titles {
    display: flex;
    align-items: flex-end;
  }
  .actions {
    margin-top: 20px;
  }
  .link {
    color: $teal;
    font-weight: 800;
    font-family: BwModelicaS001;
    font-size: 20px;
  }
}