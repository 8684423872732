@import '../../../colors.scss';

.depositdebit-wrap {
  button {
    font-weight: 700;
    font-size: 14px;
  }
  .loader-wrap {
    padding: 200px 0;
  }
  .link {
    color: $teal;
    font-weight: 800;
    font-family: BwModelicaS001;
    font-size: 20px;
  }
  .initial-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    .export-btn {
      color: $teal;
      background-color: $offTeal;
      border-color: $offTeal;
    }
  }
  .actions-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 35px;
    .form {
      width: 100%;
      .btn-wrap {
        text-align: right;
      }
      .create-btn {
        background-color: $blue;
        border-color: $blue;
      }
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  } 
  .deposit-debit {
    font-weight: 700;
    .normal {
      font-weight: 400;
    }
    .tag {
      display: inline-block;
      padding: 3px 12px;
      border: 1px solid;
      border-radius: 2px;
    }
    .debit {
      border-color: $red;
      background-color: $offRed;
      color: $red;
    }
    .deposit {
      border-color: $teal;
      color: $teal;
      background-color: $offTeal;
    }
    .red {
      color: $red;
    }
    .teal {
      color: $teal;
    }
    .action-btn-wrap {
      display: flex;
      justify-content: flex-end;
      .delete-btn {
        margin-left: 10px;
        background-color: $offRed;
        border-color: $offRed;
        color: $red;
      }
      .edit-btn {
        background-color: $offPurple;
        border-color: $offPurple;
        color: $purple;
      }
    }
  }
}