@import '../../../colors.scss';

.order-detail-wrap {
  .label-tunicaragua {
    width: 130px;
  }
  .id-client {
    margin-left: 50px;
    display: block;
  }
  .tuNicaragua {
    margin-bottom: 50px;
  }
  .replace a {
    color: $teal;
    font-weight: 500;
  }
  .main-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .back {
      min-width: 35px;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $darkBlue;
      border-radius: 100%;
      color: $white;
      margin-right: 15px;
      font-size: 25px;
      line-height: 18px;
    }
    .circle {
      display: flex;
      width: 28px;
      height: 28px;
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      background-color: $offGreen;
      color: $green;
      margin: 5px 20px 0 0;
      i {
        font-size: 16px !important;
        line-height: 0;
      }
      &.danger {
        background-color: $offRed;
        color: $red;
      }
    }
    .name-client{
      display: flex;
      align-items: center;
    }
    span {
      display: block;
      font-weight: 800;
      font-family: BwModelicaS001;
    }
    .label {
      font-size: 24px;
    }
    .value {
      color: $teal;
    }
    .ticket {
      text-transform: uppercase;
    }
    .order-main-wrap {
      display: flex;
      i {
        font-size: 22px;
        display: inline-block;
        &.blue {
          color: $blue;
          margin-top: 3px;
        }
        &.red {
          margin-top: 3px;
          color: $red;
        }
      }
    }
    .order {
      text-align: right;
      margin-left: 20px;
    }
  }

  .order_scores {
    .right {
      float: right;
    }
  }

  .item-client {
    &.agent {
      text-align: right;
    }
    span {
      display: block;
    }
    .label {
      color: $gray;
      font-weight: 700;
      font-size: 10px;
      margin-bottom: 5px;
    }
    .value {
      font-weight: 500;
    }
    .id {
      font-weight: 700;
      color: $teal;
      font-size: 10px;
    }
  }
  .client-info {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .principal {
      display: flex;
      .item-client {
        margin-right: 40px;
      }
    }
  }
  .times-wrap {
    display: flex;
    margin: 40px 0;
    background-color: #FDFDFE;
    border: 1px solid $borderGray;
    padding: 20px;
    border-radius: 10px;
    align-items: center;
    justify-content: space-around;
    .item {
      span {
        display: block;
      }
      .label {
        font-weight: 700;
        color: $gray;
        font-size: 10px;
      }
      .value {
        font-weight: 500;
      }
    }
  }
  .box {
    background-color: #FDFDFE;
    border: 1px solid $borderGray;
  }
  .detail-wrap {
    background-color: $offGray;
    border-radius: 10px;
    .header-detail {
      font-family: BwModelicaS001;
      font-weight: 800;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
    }
  }
  .results {
    padding: 15px 15px 0 15px;
    border-bottom: 10px solid $borderGray;
    .item {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      margin-bottom: 15px;
      color: #BDC1CA;
      &.total {
        color: $black;
      }
    }
  }
  .partner-info {
    border-top: 1px solid $borderGray;
    border-bottom: 1px solid $borderGray;
    padding: 15px;
    display: flex;
    .partner-logo {
      width: 55px;
      min-width: 55px;
      height: 55px;
      margin-right: 15px;
      background-color: $white;
      border-radius: 100%;
      border: 1px solid $borderGray;
      display: flex;
      align-items: center;
      justify-content: center;
      background-position: center center;
      background-size: cover;
      img {
        width: 22px;
        margin-left: -2px;
      }
    }
    .partner-name {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-right: 15px;
      .name {
        font-weight: 800;
        font-family: BwModelicaS001;
      }
      .type { 
        font-size: 12px;
      }
    }
  }
  .address-wrap {
    padding: 15px;
    border-bottom: 1px solid $borderGray;
    .title-address {
      font-weight: 700;
      display: block;
      margin-bottom: 10px;
    }
    .address-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      span {
        font-weight: 700;
      }
    }
    .texts {
      .name {
        font-weight: 700;
      }
      .address-preview {
        font-size: 13px;
        font-weight: 400;
        font-family: Nunito;
      }
    }
    .notes-wrap {
      margin-top: 25px;
      margin-bottom: 10px;
      .notes {
        background-color: #E1E3E6;
        font-weight: 500;
        border-color: #E1E3E6;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .payment-wrap {
    padding: 15px;
    .payment-header {
      font-weight: 700;
      margin-bottom: 20px;
    }
    .payments {
      .payment {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        svg, .card {
          margin-right: 15px;
          margin-bottom: 15px;
        }
        .card {
          width: 45px;
          height: 34px;
        }
        .payment-info {
          flex: 1;
          border-bottom: 1px solid $borderGray;
          padding-bottom: 15px;
          display: flex;
          align-items: center;
          margin-right: -15px;
          padding-right: 15px;
          .texts {
            flex: 1;
            span {
              display: block;
            }
            .name {
              font-weight: 700;
              text-transform: capitalize;
            }
            .description {
              font-size: 12px;
            }
          }
        }
      }
      .card-data {
        margin-top: 10px;
        margin-bottom: -50px;
        h4 {
          text-transform: uppercase;
          font-size: 10px;
          letter-spacing: 0.7px;
          font-weight: bolder;
          margin-bottom: 15px;
        }
      }
    }
  }
  .reverse-btn {
    background-color: $offRed;
    color: $red;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    font-size: 16px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    & i {
      line-height: 16px;
    }
  }
}